import React, { Fragment, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { Switch, Route, Redirect } from 'react-router-dom';
// import AuthGuard from './components/Auth/AuthGuard';
// import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Index from './views/Index';
import Compliance from './views/Compliance';
import Denuncias from './views/Denuncias';

// import Login from './views/Login';
// import Verification from './views/Verification';
// import PasswordRecovery from './views/PasswordRecovery';
// import Register from './views/Register';
// import PostRegister from './views/Register/PostRegister';

// import UserListView from './views/Admin/Users/UserListView';
// import { ChildrenProps, RouteConfig } from './types';
import { RouteConfig } from './types';
// import UpsertView from './views/Admin/Users/UpsertView';
// import { ADMIN } from './utils/user_types';
// import Unauthorized from './views/Unauthorized';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Index />,
  },
  {
    id: 2,
    exact: true,
    path: '/compliance',
    component: () => <Compliance />,
  },
  {
    id: 3,
    exact: true,
    path: '/denuncias',
    component: () => <Denuncias />,
  },
  // {
  //   id: 2,
  //   exact: true,
  //   path: '/login',
  //   component: () => <Login />,
  // },
  // // TODO: Si el usuario no puede realizar su propio registro
  // // (desde fuera del Dashboard) comentar esta ruta.
  // {
  //   id: 3,
  //   exact: true,
  //   path: '/register',
  //   component: () => <Register />,
  // },
  // {
  //   id: 4,
  //   exact: true,
  //   path: '/user-verification/:token',
  //   component: () => <Verification />,
  // },
  // {
  //   id: 5,
  //   exact: true,
  //   path: '/reset-password/:token',
  //   component: () => <PasswordRecovery />,
  // },
  // {
  //   id: 6,
  //   exact: true,
  //   path: '/reset-password?token=:token',
  //   component: () => <Redirect to="/reset-password/:token" />,
  // },
  // {
  //   id: 7,
  //   exact: true,
  //   path: '/postRegister',
  //   component: () => <PostRegister />,
  // },
  // {
  //   id: 8,
  //   exact: true,
  //   path: '/sin-autorizacion',
  //   guard: (props: ChildrenProps) => <AuthGuard {...props} />,
  //   component: () => <Unauthorized />,
  // },
  // {
  //   id: 10,
  //   path: '/administracion',
  //   guard: (props: ChildrenProps) => <AuthGuard {...props} admittedRoles={[ADMIN]} />,
  //   layout: DashboardLayout,
  //   routes: [
  //     {
  //       id: 11,
  //       exact: true,
  //       path: '/administracion',
  //       component: () => <Redirect to="/administracion/usuarios" />,
  //     },
  //     {
  //       id: 12,
  //       exact: true,
  //       path: '/administracion/usuarios',
  //       component: () => <UserListView />,
  //     },
  //     {
  //       id: 13,
  //       exact: true,
  //       path: '/administracion/usuarios/crear',
  //       component: () => <UpsertView />,
  //     },
  //     {
  //       id: 14,
  //       exact: true,
  //       path: '/administracion/usuarios/:id/editar',
  //       component: () => <UpsertView />,
  //     },
  //     {
  //       id: 15,
  //       component: () => <Redirect to="/administracion/usuarios" />,
  //     },
  //   ],
  // },
];

const renderRoutes = (routes: RouteConfig[]) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: RouteConfig, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component as React.ElementType;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
