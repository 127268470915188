import React from 'react';

import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import RecyclingIcon from '@mui/icons-material/Recycling';
import LandscapeIcon from '@mui/icons-material/Landscape';

import useStyles from './styles';

function WhatWeDo() {
  const styles = useStyles();

  return (
    <div className={styles.root} id="what-we-do">
      <div className={styles.whatWeDoContainer}>
        <h2>Qué Hacemos</h2>
        <p>Ofrecemos apoyo a la gestión sostenible de negocios</p>
        <div className={styles.whatWeDoContent}>
          <div className={styles.whatWeDoContentItem}>
            <div className={styles.whatWeDoContentItemImg}>
              <EnergySavingsLeafIcon />
            </div>
            <h3>Gestión Sostenible de Proyectos</h3>
            <hr />
            <ul>
              <li>EIA, DIA, Consultas de Pertinencia.</li>
              <li>
                Estudios Socio-Ambientales Específicos:
                Biota, Medio Humano, Patrimonio Cultural, etc.
              </li>
              <li>Estimación de emisiones.</li>
            </ul>
          </div>
          <div className={styles.whatWeDoContentItem}>
            <div className={styles.whatWeDoContentItemImg}>
              <RecyclingIcon />
            </div>
            <h3>Gestión Sostenible de Operaciones</h3>
            <hr />
            <ul>
              <li>
                Gestión Integral de Residuos líquidos y sólidos:
                Transporte, gestión de disposición final, certificados, reporte SINADER.
              </li>
              <li>Clasificación y Reciclaje.</li>
              <li>Seguimiento RCAs, soporte técnico en Programas de Cumplimiento SMA.</li>
              <li>Muestreo de aguas y RILes.</li>
            </ul>
          </div>
          <div className={styles.whatWeDoContentItem}>
            <div className={styles.whatWeDoContentItemImg}>
              <LandscapeIcon />
            </div>
            <h3>Gestión Sostenible Corporativa</h3>
            <hr />
            <ul>
              <li>Estimación de huella de carbono, huella hídrica, huella de residuos.</li>
              <li>
                Apoyo para la obtención de certificaciones de performance ambiental (eco-labeling).
              </li>
              <li>Planes de compensación de emisiones.</li>
              <li>Evaluación e implementación de proyectos ERNC.</li>
              <li>Apoyo en procesos de Participación Ciudadana.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
