import React from 'react';

import useStyles from './styles';

function Footer() {
  const styles = useStyles();

  return (
    <div className={styles.footer}>
      &copy; 2024 Patagonia Sostenible de Aysén SpA
    </div>
  );
}

export default Footer;
