import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '45px',
  },
  bannerContainer: {
    background: 'url(https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/jpg/sustainability.jpg) no-repeat center center',
    backgroundSize: 'cover',
    minHeight: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '0px 25px',
    color: '#fff',
    '& h1': {
      fontSize: '48px',
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '24px',
    },
    '& span': {
      color: '#92bf21',
    },
  },
  bannerButton: {
    marginTop: '20px',
    backgroundColor: '#92bf21',
    color: '#fff',
    padding: '10px 20px',
    width: '250px',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;
