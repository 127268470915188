import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  aboutUsContainer: {
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px',
    gap: '30px',
    margin: '0px 25px',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      margin: '50px 25px',
    },
  },
  aboutUsContent: {
    width: '100%',
    maxWidth: '580px',
    backgroundColor: '#fff',
    borderLeft: '10px solid #92bf21',
    padding: '30px 20px',
    '& h2': {
      color: '#029e99',
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
  },
  aboutUsContentImg: {
    '& img': {
      borderRadius: '10px',
      width: '100%',
      maxWidth: '580px',
      height: '100%',
      maxHeight: '285px',
      objectFit: 'cover',
    },
  },
  aboutUsContentText: {
    display: 'flex',
    flexDirection: 'column',
  },
  aboutUsContentTextItem: {
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
  },
  aboutUsContentTextItemIcon: {
    '& svg': {
      color: '#92bf21',
      fontSize: '30px',
      marginRight: '10px',
    },
  },
  aboutUsContentTextItemText: {
    fontSize: '14px',
  },
  ourFocusContainer: {
    backgroundColor: '#92bf21',
    padding: '50px 0px 100px 0px',
    '& h2': {
      color: '#fff',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '50px',
    },
  },
  ourFocusContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
    },
  },
  ourFocusContentItem: {
    width: '320px',
    height: '200px',
    background: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '40px 20px',
  },
  ourFocusContentItemImg: {
    '& svg': {
      color: '#029e99',
      fontSize: '50px',
      marginRight: '10px',
    },
  },
  ourFocusContentItemText: {
    fontSize: '14px',
  },
}));

export default useStyles;
