/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface RegisterRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /**
   * @pattern ^[a-zA-Z0-9]
   * @example null
   */
  password?: string | null;
  /** @example "user" */
  role?: string;
}

export interface PutDeletedUserRequest {
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /** @example "user" */
  role?: string;
}

export interface LoginRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
}

export interface VerificationRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface ResetPasswordRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface RecoverPasswordRequest {
  /**
   * Email de usuario
   * @format email
   * @minLength 3
   * @maxLength 255
   */
  email: string;
}

export interface PostGmailLoginRequest {
  /**
   * Access Token desde Gmail
   * @example "TODO"
   */
  accessToken: string;
}

export interface RegisterResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario creado con éxito" */
  message?: string;
  user?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  };
}

export interface LoginResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login exitoso" */
  message?: string;
  user?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  };
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token?: string;
}

export interface ErrorResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login fallido" */
  message?: string;
  /** @example "Error en el servidor" */
  errorMessage?: string;
}

export interface VerificationResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario verificado correctamente" */
  message?: string;
}

export interface ResetPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Contraseña actualizada" */
  message?: string;
}

export interface RecoverPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Correo de recuperar contraseña enviado" */
  message?: string;
}

export interface PostGmailLoginResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login con Gmail exitoso" */
  message?: string;
  user?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  };
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token?: string;
}

export interface UpdateUserSchema {
  /**
   * @format float
   * @example 1
   */
  id: number;
  /** @example "María" */
  name?: string;
  /** @example "Rodríguez" */
  lastname?: string;
  /** @example true */
  active?: boolean;
  /** @example "user" */
  role?: string;
}

export interface UpdateUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario editado exitosamente" */
  message?: string;
  editedUser?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  };
}

export interface GetUsersPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuarios encontrados exitosamente" */
  message?: string;
  users?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  }[];
  /** @example 1 */
  total?: number;
}

export interface DeleteUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario/s eliminado/s exitosamente" */
  message?: string;
}

export interface GetUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario encontrado" */
  message?: string;
  user?: {
    /** @example "example@gmail.com" */
    email?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "user" */
    role?: string;
    /** @example 1 */
    id?: number;
    /** @example null */
    password?: string | null;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example false */
    active?: boolean;
    /** @example false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
  };
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Template REST API Docs
 * @version 0.1.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  register = {
    /**
     * @description Creates a new user that must afterwards verify the account with a token
     *
     * @tags Auth
     * @name RegisterCreate
     * @request POST:/register
     * @secure
     */
    registerCreate: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<RegisterResponse, void>({
        path: `/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * @description Authenticates a user by email and password and returns a JWT token
     *
     * @tags Auth
     * @name LoginCreate
     * @request POST:/login
     * @secure
     */
    loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse | void>({
        path: `/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  recoverPassword = {
    /**
     * @description Sends an email to the user with instructions to recover password
     *
     * @tags Auth
     * @name RecoverPasswordCreate
     * @request POST:/recover-password
     * @secure
     */
    recoverPasswordCreate: (data: RecoverPasswordRequest, params: RequestParams = {}) =>
      this.request<RecoverPasswordResponse, void>({
        path: `/recover-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetPassword = {
    /**
     * @description Resets a user's password
     *
     * @tags Auth
     * @name ResetPasswordCreate
     * @request POST:/reset-password
     * @secure
     */
    resetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<ResetPasswordResponse, void>({
        path: `/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  userVerification = {
    /**
     * @description Verifies user account with a token
     *
     * @tags Auth
     * @name UserVerificationCreate
     * @request POST:/user-verification
     * @secure
     */
    userVerificationCreate: (data: VerificationRequest, params: RequestParams = {}) =>
      this.request<VerificationResponse, void>({
        path: `/user-verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  gmailLogin = {
    /**
     * @description Logs in with gmail. If the user exists in database, retrieves it. If not, user is created
     *
     * @tags User
     * @name GmailLoginList
     * @request GET:/gmail-login
     * @secure
     */
    gmailLoginList: (
      query: {
        accessToken: string;
      },
      data?: any,
      params: RequestParams = {},
    ) =>
      this.request<PostGmailLoginResponse, void>({
        path: `/gmail-login`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * @description Responds if the app is up and running
     *
     * @tags Healthcheck
     * @name HealthList
     * @request GET:/health
     * @secure
     */
    healthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * @description Get users information
     *
     * @tags User
     * @name UsersList
     * @request GET:/users
     * @secure
     */
    usersList: (
      query?: {
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUsersPerPageResponse, void>({
        path: `/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a user information
     *
     * @tags User
     * @name UsersUpdate
     * @request PUT:/users/{id}
     * @secure
     */
    usersUpdate: (id: string, data: UpdateUserSchema, params: RequestParams = {}) =>
      this.request<UpdateUserResponse, void>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a user by its ID. Needs to be accessible to every user,
     *
     * @tags User
     * @name UsersDetail
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/users/${id}`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a users array by IDs.
     *
     * @tags User
     * @name UsersDelete
     * @request DELETE:/users/{userIds}
     * @secure
     */
    usersDelete: (userIds: string[], params: RequestParams = {}) =>
      this.request<DeleteUserResponse, void>({
        path: `/users/${userIds}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description get a current user information
     *
     * @tags User
     * @name UserList
     * @request GET:/user
     * @secure
     */
    userList: (data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/user`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
