import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clientsContainer: {
    '& h2': {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#029e99',
      marginBottom: '50px',
    },
    maxWidth: '100vw',
    textAlign: 'center',
    marginTop: '50px',
  },
  clientsList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '100px',
    maxWidth: '1280px',
    overflow: 'hidden',
    [theme.breakpoints.down(650)]: {
      flexDirection: 'column',
    },
  },
  clientItem: {
    [theme.breakpoints.down(992)]: {
      margin: '0px 25px',
    },
    [theme.breakpoints.down(768)]: {
      margin: '0px 10px',
    },
    [theme.breakpoints.down(650)]: {
      margin: '25px 10px',
    },
    margin: '0px 50px',
    maxWidth: '150px',
    maxHeight: '150px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));

export default useStyles;
