import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#92bf21',
    minHeight: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactForm: {
    width: '100%',
    maxWidth: '1000px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    margin: '25px',
    [theme.breakpoints.down(992)]: {
      flexDirection: 'column',
    },
  },
  contactFormInfo: {
    width: '100%',
    maxWidth: '350px',
    backgroundColor: '#029e99',
    [theme.breakpoints.down(992)]: {
      maxWidth: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '75px 25px',
  },
  contactFormInfoItem: {
    minHeight: '50px',
    marginBottom: '25px',
  },
  contactFormInfoItemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    '& svg': {
      color: '#fff',
    },
    color: '#92bf21',
    fontWeight: '500',
    fontSize: '18px',
  },
  contactFormInfoItemValue: {
    color: '#fff',
    padding: '5px 0px 10px 35px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
    },
  },
  contactFormForm: {
    width: '100%',
    height: '100%',
    padding: '25px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h2': {
      color: '#029e99',
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
  },
  contactFormFormInputs: {
    width: '100%',
  },
  contactFormFormInput: {
    margin: '10px 0px 0px 0px',
    width: '100%',
    '& input': {
      width: '100%',
      padding: '10px',
      border: '0px',
      borderBottom: '1px solid #92bf21',
      backgroundColor: '#ededed',
      fontFamily: 'Montserrat',
    },
    '& textarea': {
      width: '100%',
      padding: '10px',
      border: '0px',
      borderBottom: '1px solid #92bf21',
      backgroundColor: '#ededed',
      fontFamily: 'Montserrat',
    },
    '& input:focus': {
      outline: 'none',
    },
    '& textarea:focus': {
      outline: 'none',
    },
  },
  contactFormDoubleInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
  },
  contactFormFormButton: {
    marginTop: '20px',
    backgroundColor: '#92bf21',
    color: '#fff',
    padding: '10px 20px',
    width: '160px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  contactFormMessage: {
    height: '30px',
    marginTop: '10px',
  },
  contactFormMessageSuccess: {
    color: '#92bf21',
  },
  contactFormMessageError: {
    color: 'red',
  },
}));

export default useStyles;
