import React from 'react';

import useStyles from './styles';

function Clients() {
  const styles = useStyles();

  return (
    <div className={styles.root} id="clients">
      <div className={styles.clientsContainer}>
        <h2>Nuestros Clientes</h2>
        <div className={styles.clientsList}>
          <div className={styles.clientItem}>
            <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo-aguas.png" alt="Aguas Patagonia" />
          </div>
          <div className={styles.clientItem}>
            <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo-agrosuper.png" alt="Agrosuper" />
          </div>
          <div className={styles.clientItem}>
            <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo-hospital-regional-coyhaique.png" alt="Hospital Regional de Coyhaique" />
          </div>
          <div className={styles.clientItem}>
            <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo-sodimac.svg" alt="Sodimac" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
