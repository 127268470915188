import React from 'react';

import GroupsIcon from '@mui/icons-material/Groups';
import SpaIcon from '@mui/icons-material/Spa';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckIcon from '@mui/icons-material/Check';

import useStyles from './styles';

function AboutUs() {
  const styles = useStyles();

  return (
    <div className={styles.root} id="about-us">
      <div className={styles.aboutUsContainer}>
        <div className={styles.aboutUsContent}>
          <h2>¿Quiénes Somos?</h2>
          <div className={styles.aboutUsContentText}>
            <div className={styles.aboutUsContentTextItem}>
              <div className={styles.aboutUsContentTextItemIcon}>
                <CheckIcon />
              </div>
              <div className={styles.aboutUsContentTextItemText}>
                Nuestros socios, ICAFAL Inversiones S.A. e HIDROSAN Ingeniería S.A.,
                poseen vasta experiencia desarrollando negocios en la Región.
              </div>
            </div>
            <div className={styles.aboutUsContentTextItem}>
              <div className={styles.aboutUsContentTextItemIcon}>
                <CheckIcon />
              </div>
              <div className={styles.aboutUsContentTextItemText}>
                Poseemos un equipo multidisciplinario de colaboradores con experiencia
                en el sector público y privado como también maquinaria disponible para
                abordar desafíos de diversa naturaleza.
              </div>
            </div>
            <div className={styles.aboutUsContentTextItem}>
              <div className={styles.aboutUsContentTextItemIcon}>
                <CheckIcon />
              </div>
              <div className={styles.aboutUsContentTextItemText}>
                Somos una empresa local, con oficinas permanentes en el centro de Coyhaique.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aboutUsContentImg}>
          <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/jpg/patagonia_lake_2.jpg" alt="Quiénes Somos" />
        </div>
      </div>
      <div className={styles.ourFocusContainer}>
        <h2>Nuestro Enfoque</h2>
        <div className={styles.ourFocusContent}>
          <div className={styles.ourFocusContentItem}>
            <div className={styles.ourFocusContentItemImg}>
              <GroupsIcon />
            </div>
            <div className={styles.ourFocusContentItemText}>
              <p>
                Preferimos contratar profesionales y mano de obra en el mercado local,
                y prestar servicios por medio de la encadenación con proveedores regionales.
              </p>
            </div>
          </div>
          <div className={styles.ourFocusContentItem}>
            <div className={styles.ourFocusContentItemImg}>
              <SpaIcon />
            </div>
            <div className={styles.ourFocusContentItemText}>
              <p>
                Queremos apoyar la adaptación al cambio climático y el desarrollo de modelos
                de negocios de economía circular en la Región de Aysén.
              </p>
            </div>
          </div>
          <div className={styles.ourFocusContentItem}>
            <div className={styles.ourFocusContentItemImg}>
              <TrendingUpIcon />
            </div>
            <div className={styles.ourFocusContentItemText}>
              <p>
                Apostamos por una relación de largo plazo con nuestros clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
