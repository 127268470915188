import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 0px',
    maxWidth: '300px',
    margin: '0 auto',
    [theme.breakpoints.up(600)]: {
      maxWidth: '380px',
    },
    [theme.breakpoints.up(1024)]: {
      maxWidth: '720px',
    },
    '& h1': {
      fontSize: '48px',
      margin: '25px 0px',
    },
    '& h2': {
      fontSize: '24px',
      margin: '20px 0px',
    },
    '& h3': {
      fontSize: '20px',
      margin: '20px 0px',
    },
    '& h4': {
      fontSize: '18px',
      margin: '20px 0px',
    },
    '& p': {
      margin: '15px 0px',
    },
    '& ul': {
      margin: '15px 0px',
    },
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  denunciaFiles: {
    '& a': {
      display: 'block',
      margin: '10px 0px',
    },
  },
}));

export default useStyles;
