import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    width: '100vw',
  },
  navbarTop: {
    backgroundColor: '#92bf21',
    width: '100%',
    height: '45px',
  },
  navbarTopContent: {
    maxWidth: '1280px',
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 25px',
    color: '#fff',
    fontSize: '14px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
    },
  },
  navbarTopContentItem: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      margin: '5px 5px 0px 0px',
    },
  },
  navbarTopContentItemHide: {
    display: 'none',
    [theme.breakpoints.up(891)]: {
      display: 'flex',
    },
  },
  navbarBottom: {
    backgroundColor: '#101010',
    opacity: 0.8,
    color: '#fff',
    width: '100%',
    height: '65px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  navbarBottomWrapper: {
    maxWidth: '1280px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
  navbarBottomContent: {
    [theme.breakpoints.down(1280)]: {
      display: 'none',
    },
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 25px',
    color: '#fff',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
    },
  },
  navbarBottomContentItem: {
    padding: '0px 15px',
    textTransform: 'uppercase',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
    },
  },
  navbarBottomContentItemContact: {
    padding: '0px 15px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center',
    border: '2px solid #92bf21',
    borderRadius: '3px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
  },
  navbarBottomContentMobile: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 25px',
    [theme.breakpoints.up(1280)]: {
      display: 'none',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:-webkit-any-link': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:visited': {
      color: '#fff',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
    '& a:hover': {
      color: '#fff',
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& svg': {
        margin: '0px 5px 0px 0px',
      },
    },
  },
  navbarLogo: {
    [theme.breakpoints.down(500)]: {
      left: '5%',
      width: '120px',
      height: '60px',
    },
    position: 'absolute',
    left: '15%',
    top: '0px',
    height: '120px',
    width: '240px',
    backgroundColor: '#fff',
    '& img': {
      maxWidth: '200px',
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
}));

export default useStyles;
