import React from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

import useStyles from './styles';

function Navbar() {
  const styles = useStyles();

  return (
    <div className={styles.navbar}>
      <div className={styles.navbarTop}>
        <div className={styles.navbarTopContent}>
          <div className={styles.navbarTopContentItem}>
            <a href="mailto:contacto@patagoniasostenible.cl"><MailOutlineIcon /></a>
            <div className={styles.navbarTopContentItemHide}>
              <a href="mailto:contacto@patagoniasostenible.cl">contacto@patagoniasostenible.cl</a>
            </div>
          </div>
          <div className={styles.navbarTopContentItem}>
            <a href="tel:+56932555726"><PhoneIcon /></a>
            <div className={styles.navbarTopContentItemHide}>
              <a href="tel:+56932555726">+569 3255 5726</a>
            </div>
          </div>
          <div className={styles.navbarTopContentItem}>
            <a href="https://www.linkedin.com/company/patagonia-sostenible/" target="_blank" rel="noreferrer">
              <LinkedInIcon />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.navbarBottom}>
        <div className={styles.navbarBottomWrapper}>
          <div className={styles.navbarBottomContent}>
            <div className={styles.navbarBottomContentItem}><a href="#home">Inicio</a></div>
            <div className={styles.navbarBottomContentItem}><a href="#about-us">Quiénes Somos</a></div>
            <div className={styles.navbarBottomContentItem}><a href="#what-we-do">Qué Hacemos</a></div>
            <div className={styles.navbarBottomContentItem}><a href="#clients">Clientes</a></div>
            <div className={styles.navbarBottomContentItem}>
              <a href="/compliance">Compliance</a>
            </div>
            <div className={styles.navbarBottomContentItemContact}>
              <a href="#contact">
                <EmailIcon />
                Contacto
              </a>
            </div>
          </div>
          <div className={styles.navbarBottomContentMobile}>
            <a href="#contact">
              <EmailIcon />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.navbarLogo}>
        <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo_paso.png" alt="Patagonia Sostenible" />
      </div>
    </div>
  );
}

export default Navbar;
