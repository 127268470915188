import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '800px',
    [theme.breakpoints.down(1080)]: {
      height: '100%',
    },
  },
  whatWeDoContainer: {
    [theme.breakpoints.down(1080)]: {
      padding: '35px 0px',
    },
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'url(https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/jpg/patagonia_lake_3.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& h2': {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#fff',
      marginBottom: '10px',
      textAlign: 'center',
    },
    '& p': {
      fontSize: '18px',
      color: '#fff',
      textAlign: 'center',
    },
  },
  whatWeDoContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '50px',
    gap: '25px',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
    },
  },
  whatWeDoContentItem: {
    width: '320px',
    height: '500px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '40px',
    borderRadius: '10px',
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#029e99',
      textAlign: 'center',
      margin: '20px 0px',
    },
    '& ul': {
      padding: '0px 30px',
      fontSize: '14px',
    },
    '& hr': {
      width: '60px',
      border: '3px solid #92bf21',
      margin: '10px 0px 20px 0px',
    },
  },
  whatWeDoContentItemImg: {
    '& svg': {
      fontSize: '80px',
      color: '#92bf21',
    },
  },
}));

export default useStyles;
