import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 0px',
    marginBottom: '50px',
    maxWidth: '300px',
    margin: '0 auto',
    [theme.breakpoints.up(600)]: {
      maxWidth: '380px',
    },
    [theme.breakpoints.up(1024)]: {
      maxWidth: '720px',
    },
    '& h1': {
      fontSize: '48px',
      margin: '25px 0px',
    },
    '& h2': {
      fontSize: '24px',
      margin: '20px 0px',
    },
    '& h3': {
      fontSize: '20px',
      margin: '20px 0px',
    },
    '& h4': {
      fontSize: '18px',
      margin: '20px 0px',
    },
    '& p': {
      margin: '15px 0px',
    },
    '& ul': {
      margin: '15px 0px',
    },
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  denunciasItem: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    paddingBottom: '5px',
  },
  denunciasItemRow: {},
  denunciasItemRadio: {
    padding: '4px 0px',
    '& label': {
      paddingLeft: '5px',
    },
  },
  denunciasItemInfo: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  denunciasItemText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      fontWeight: 600,
    },
  },
  denunciasInformationInput: {
    height: '30px',
    width: '250px',
    border: '1px solid #42518A',
    borderRadius: '3px',
    marginTop: '10px',
    marginLeft: '15px',
    paddingLeft: '5px',
  },
  denunciasInformationSubmit: {
    float: 'left',
    backgroundColor: '#222648',
    border: 'none',
    borderRadius: '5px',
    color: '#ffffff',
    width: '90px',
    height: '30px',
    margin: '0px 15px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#000',
      backgroundColor: '#ccc',
    },
  },
  denunciasInformationSubmitFile: {
    float: 'left',
    backgroundColor: '#222648',
    border: 'none',
    borderRadius: '5px',
    color: '#ffffff',
    width: '180px',
    height: '30px',
    margin: '0px 15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  denunciasInformationSubmitBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  denunciasContent: {
    marginLeft: '20px',
    backgroundColor: '#efefef',
    padding: '5px',
  },
  denunciaFiles: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  pSmall: {
    fontSize: '12px',
  },
  pError: {
    fontSize: '16px',
    color: 'red',
  },
}));

export default useStyles;
