import React, { useState } from 'react';

import axios from 'axios';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';

import useStyles from './styles';

import validateEmail from '../../../utils/validator';

function Contact() {
  const styles = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!validateEmail(email)) {
      setError('Por favor ingrese un correo electrónico válido.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('/api/contact', {
        name,
        email,
        phone,
        subject,
        message,
      });

      setLoading(false);

      if (response.data.success) {
        setSuccess(true);
        setName('');
        setEmail('');
        setSubject('');
        setPhone('');
        setMessage('');
      } else {
        setError('Ha ocurrido un error. Por favor intente nuevamente más tarde.');
      }
    } catch (err: unknown) {
      setLoading(false);
      if (err instanceof Error && 'response' in err) {
        const axiosError = err as { response?: { data?: { error?: string } } };
        if (axiosError.response?.data?.error) {
          setError(axiosError.response.data.error);
        } else {
          setError('Ha ocurrido un error. Por favor intente nuevamente más tarde.');
        }
      } else {
        setError('Ha ocurrido un error. Por favor intente nuevamente más tarde.');
      }
    }
  };

  return (
    <div className={styles.root} id="contact">
      <div className={styles.contactForm}>
        <div className={styles.contactFormInfo}>
          <div className={styles.contactFormInfoItem}>
            <div className={styles.contactFormInfoItemTitle}>
              <EmailIcon />
              Contáctanos
            </div>
            <div className={styles.contactFormInfoItemValue}>
              <a href="mailto:contacto@patagoniasostenible.cl">contacto@patagoniasostenible.cl</a>
            </div>
          </div>
          <div className={styles.contactFormInfoItem}>
            <div className={styles.contactFormInfoItemTitle}>
              <PhoneIcon />
              Teléfono
            </div>
            <div className={styles.contactFormInfoItemValue}>
              <a href="tel:+56932555726">+569 3255 5726</a>
            </div>
          </div>
          <div className={styles.contactFormInfoItem}>
          < div className={styles.contactFormInfoItemTitle}>
              <PlaceIcon />
              Ubicación
            </div>
            <div className={styles.contactFormInfoItemValue}>
              Coyhaique - Región de Aysén
              <br />
              Patagonia Chilena
            </div>
          </div>
        </div>
        <div className={styles.contactFormForm}>
          <div><h2>Formulario de contacto</h2></div>
          <div className={styles.contactFormFormInputs}>
            <div className={styles.contactFormFormInput}>
              <input type="text" placeholder="Tu Nombre" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className={styles.contactFormFormInput}>
              <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className={styles.contactFormDoubleInput}>
              <div className={styles.contactFormFormInput}>
                <input type="text" placeholder="Asunto" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </div>
              <div className={styles.contactFormFormInput}>
                <input type="text" placeholder="Teléfono" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
            </div>
            <div className={styles.contactFormFormInput}>
              <textarea placeholder="Mensaje" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>
          </div>
          <div>
            <div
              className={styles.contactFormFormButton}
              onClick={loading ? () => {} : handleSubmit}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </div>
            <div className={styles.contactFormMessage}>
              {success ? (
                <p className={styles.contactFormMessageSuccess}>Mensaje enviado correctamente</p>
              ) : null}
              {error ? (
                <p className={styles.contactFormMessageError}>{error}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
