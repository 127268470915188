import {
  DEFAULT_API_ERROR_MESSAGE,
  EXPIRED_LINK,
  GMAIL_CONNECTION_ERROR,
  GMAIL_USER_NOT_FOUND,
  INCORRECT_LOGIN,
  INVALID_ARGUMENT,
  INVALID_ROLE,
  INVALID_TOKEN,
  LOGIN_WITH_EMAIL,
  NO_TOKEN,
  NO_USERS_FOUND,
  PASSWORDS_DO_NOT_MATCH,
  PROHIBITED,
  REQUIRED_EMAIL,
  REQUIRED_TOKEN,
  TOKEN_NOT_GENERATED,
  USED_INVALID_TOKEN,
  USER_ALREADY_EXIST,
  USER_DOES_NOT_EXIST,
  USER_INACTIVE,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  WRONG_CREDENTIALS,
} from '../utils/errors';

import {
  PASSWORD_UPDATED,
  RECOVER_PASSWORD_MAIL_SENT,
  SUCCESSFUL_LOGIN,
  USERS_DELETED,
  USERS_FOUND,
  USER_CREATED,
  USER_EDITED,
  USER_FOUND,
  USER_VERIFIED,
} from '../utils/success';

export default {
  'login.title': 'Sign in',
  'login.subtitle': 'Login to the backoffice platform',
  'login.email': 'Email',
  'login.email.required': 'Email required',
  'login.email.invalidFormat': 'Invalid email format',
  'login.password': 'Password',
  'login.password.required': 'Password required',
  'login.login': 'Log in',
  'login.or': 'or',
  'login.continueGoogle': 'Continue with google',
  'login.forgottenPassword': 'Forgot Password?',
  'admin.listView.header.title': 'Users',
  'admin.listView.header.button.addUser': 'Add User',
  'admin.listView.results.sort.filter.title': 'Order',
  'admin.listView.results.sort.filter.newestFirst': 'Newest first',
  'admin.listView.results.sort.filter.oldestFirst': 'Oldest first',
  'admin.listView.results.error.cannotDeleteSameAccount': 'Cannot delete own account',
  'admin.listView.results.search.placeholder': 'Search user',
  'admin.listView.results.search.button': 'Search',
  'admin.listView.results.delete.button': 'Delete',
  'admin.listView.results.table.column.name': 'Name',
  'admin.listView.results.table.column.role': 'Role',
  'admin.listView.results.table.column.actions': 'Actions',
  'admin.listView.results.table.footer.rowsPerPage': 'Rows per page',
  'admin.listView.results.table.noData': 'No data available',
  'admin.upsert.page.title.createUser': 'Create User',
  'admin.upsert.page.title.editUser': 'Edit User',
  'admin.upsert.header.title.createUser': 'Create user',
  'admin.upsert.header.title.editUser': 'Edit user',
  'admin.upsert.header.cancel.button': 'Cancel',
  'admin.upsert.form.validation.enterName': 'You must enter a name',
  'admin.upsert.form.validation.enterLastname': 'You must enter a last name',
  'admin.upsert.form.validation.enterRole': 'You must enter a role',
  'admin.upsert.form.error.goBackToUsers.actionButton': 'Go back to users',
  'admin.upsert.form.textField.name': 'Name',
  'admin.upsert.form.textField.lastname': 'Last name',
  'admin.upsert.form.textField.email': 'Email',
  'admin.upsert.form.textField.role': 'Role',
  'admin.upsert.form.textField.role.option.admin': 'ADMIN',
  'admin.upsert.form.textField.role.option.user': 'USER',
  'admin.upsert.form.textField.state': 'State',
  'admin.upsert.form.textField.state.option.active': 'ACTIVE',
  'admin.upsert.form.textField.state.option.inactive': 'INACTIVE',
  'admin.upsert.form.card.save.button': 'Save',
  'navigation.navbar.admin.header.title': 'Administration',
  'navigation.navbar.admin.subheader.title.users': 'Users',
  'navigation.topbar.account.account': 'Account',
  'navigation.topbar.account.closeSession': 'Close Session',
  'navigation.topbar.settings.title': 'Settings',
  'navigation.topbar.settings.theme.title': 'Theme',
  'navigation.topbar.settings.language.title': 'Language',
  'navigation.topbar.settings.language.option.english': 'English',
  'navigation.topbar.settings.language.option.spanish': 'Spanish',
  'navigation.topbar.settings.save.button': 'Save',
  'noAuthorization.title': 'No authorization',
  'noAuthorization.firstMessage': 'Oops! You don\'t have permission to access this page. Please request permission from your account administrator, click ',
  'noAuthorization.here': 'here ',
  'noAuthorization.secondMessage': ' to go to your main page, or click ',
  'noAuthorization.thirdMessage': ' to change user.',
  // API ERRORS
  [NO_TOKEN]: 'Token not found',
  [INVALID_TOKEN]: 'Failed to authenticate token',
  [INVALID_ROLE]: 'Invalid role',
  [REQUIRED_TOKEN]: 'Token required',
  [EXPIRED_LINK]: 'Link expired',
  [PROHIBITED]: 'Prohibited',
  [USED_INVALID_TOKEN]: 'Token already used or invalid',
  [USER_INACTIVE]: 'Inactive user',
  [USER_DOES_NOT_EXIST]: 'User does not exist',
  [WRONG_CREDENTIALS]: 'Incorrect username or password',
  [PASSWORDS_DO_NOT_MATCH]: 'Passwords do not match',
  [TOKEN_NOT_GENERATED]: 'Token could not be generated',
  [REQUIRED_EMAIL]: 'Email required',
  [USER_NOT_REGISTERED]: 'User not registered',
  [VERIFICATION_MAIL_ERROR]: 'Error sending verification email',
  [GMAIL_USER_NOT_FOUND]: 'Gmail: user not found. Please check',
  [GMAIL_CONNECTION_ERROR]: 'Gmail connection error',
  [USER_ALREADY_EXIST]: 'Email already exists',
  [INVALID_ARGUMENT]: 'Invalid argument',
  [NO_USERS_FOUND]: 'No users found',
  [LOGIN_WITH_EMAIL]: 'User created without google. Please login with email and password',
  [INCORRECT_LOGIN]: 'Incorrect login',
  [DEFAULT_API_ERROR_MESSAGE]: 'Internal server error',
  // API SUCCESS
  [USER_VERIFIED]: 'User verified successfully',
  [SUCCESSFUL_LOGIN]: 'Successful login',
  [RECOVER_PASSWORD_MAIL_SENT]: 'Recovery password email sent',
  [PASSWORD_UPDATED]: 'Password updated',
  [USER_CREATED]: 'User created successfully',
  [USERS_FOUND]: 'Users found successfully',
  [USER_EDITED]: 'User edited successfully',
  [USER_FOUND]: 'User found',
  [USERS_DELETED]: 'User(s) deleted successfully',
};
