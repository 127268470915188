const parseDenunciaRelacion = (denunciaRelacion: string): string => {
  switch (denunciaRelacion) {
    case 'trabajador': return 'Trabajador de Aguas Patagonia';
    case 'contratista': return 'Contratista de Aguas Patagonia';
    case 'cliente': return 'Cliente de Aguas Patagonia';
    case 'otro': return 'Otro';

    default: return '';
  }
};

const parseDenunciaLugar = (denunciaLugar: string): string => {
  switch (denunciaLugar) {
    case 'oficinas-corporativas': return 'Oficinas Corporativas';
    case 'oficinas-zonales': return 'Oficinas Zonales';
    case 'plantas': return 'Plantas';
    case 'recintos': return 'Recintos';
    case 'otro': return 'Ninguna de las anteriores';

    default: return '';
  }
};

const parseDenunciaType = (denunciaType: string): string => {
  switch (denunciaType) {
    case 'soborno-funcionarios-publicos': return 'Soborno a funcionarios públicos';
    case 'contaminacion-aguas': return 'Contaminación de Aguas';
    case 'soborno-particulares': return 'Soborno entre particulares';
    case 'delitos-ley-sociedades-anonimas': return 'Delitos Ley de Sociedades Anónimas';
    case 'delitos-informaticos': return 'Delitos Informáticos';
    case 'receptacion': return 'Receptación';
    case 'falsificacion-documentos': return 'Falsificación de documentos';
    case 'delitos-ley-sea': return 'Delitos Ley SEA';
    case 'residuos-peligrosos': return 'Residuos Peligrosos';
    case 'delitos-aduaneros': return 'Delitos Aduaneros';
    case 'administracion-desleal': return 'Administración Desleal';
    case 'apropiacion-indebida': return 'Apropiación Indebida';
    case 'lavado-activos': return 'Lavado de activos';
    case 'info-privilegiada': return 'Uso Información privilegiada';
    case 'delitos-salud-publica': return 'Delitos Salud Pública (Envenenamiento Agua potable)';
    case 'incumplimiento-normas-sanitarias': return 'Ordenar concurrir al lugar de trabajo en incumplimiento de las normas sanitarias';
    case 'negociacion-incompatible': return 'Negociación Incompatible';
    case 'codigo-etica': return 'Conductas contrarias al Código de Ética';
    case 'acoso-laboral': return 'Acoso laboral';
    case 'acoso-sexual': return 'Acoso Sexual';
    case 'violencia-terceros': return 'Violencia de terceros';
    case 'consultas': return 'Consultas';

    default: return '';
  }
};

const parseDenunciaContactTime = (contactTime: string): string => {
  switch (contactTime) {
    case '9-12': return '09:00 a 12:00';
    case '12-15': return '12:00 a 15:00';
    case '15-18': return '15:00 a 18:00';

    default: return '';
  }
};

const parseDenunciaTime = (denunciaTime: string): string => {
  switch (denunciaTime) {
    case 'week': return 'Menos de una semana';
    case 'month': return 'Más de una semana y menos de un mes';
    case 'six-months': return 'Más de un mes y menos de 6 meses';
    case 'six-months-more': return 'Más de 6 meses';
    case 'unknown': return 'No sabe';

    default: return '';
  }
};

const parseDenunciaKnowledge = (denunciaKnowledge: string): string => {
  switch (denunciaKnowledge) {
    case 'heard': return 'Lo escuché';
    case 'me': return 'Me paso a mi';
    case 'observed': return 'Lo observé';
    case 'coworker': return 'Un compañero de trabajo me lo comentó';
    case 'outsider': return 'Alguien fuera de la oficina me lo comentó';
    case 'document': return 'Por casualidad me encontré un documento';
    case 'other': return 'Otro';

    default: return '';
  }
};

const parseDenunciaYesNo = (opt: string): string => {
  switch (opt) {
    case 'yes': return 'Si';
    case 'no': return 'No';
    case 'unknown': return 'No sabe / No desea revelar';

    default: return '';
  }
};

export {
  parseDenunciaRelacion,
  parseDenunciaLugar,
  parseDenunciaType,
  parseDenunciaContactTime,
  parseDenunciaTime,
  parseDenunciaKnowledge,
  parseDenunciaYesNo,
};
