import React from 'react';

import useStyles from './styles';

function Banner() {
  const styles = useStyles();

  return (
    <div className={styles.root} id="home">
      <div className={styles.bannerContainer}>
        <div className={styles.bannerContent}>
          <h1><span>Patagonia Sostenible</span> de Aysén SpA</h1>
          <p>Ofrecemos apoyo a la gestión sostenible de negocios</p>
          <div className={styles.bannerButton} onClick={() => { window.location.href = '#contact'; }}>
            Solicitar Información
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
