import React from 'react';

import useStyles from './styles';
import Navbar from './Navbar';
import Banner from './Banner';
import AboutUs from './AboutUs';
import WhatWeDo from './WhatWeDo';
import Clients from './Clients';
import Contact from './Contact';
import Footer from './Footer';

function Index() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Navbar />
      <Banner />
      <AboutUs />
      <WhatWeDo />
      <Clients />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
