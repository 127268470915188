import React from 'react';

import useStyles from './styles';

function Compliance() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src="https://paso-public-files.s3.sa-east-1.amazonaws.com/assets/img/png/logo_paso.png" alt="Patagonia Sostenible" />
      </div>
      <br />
      <h1>Modelo de Prevención de Delitos Ley 20.393</h1>
      <p>
        Con el objetivo de reforzar su compromiso con el cumplimiento de las normas legales y las
        buenas prácticas corporativas, PATAGONIA SOSTENIBLE ha decidido implementar un Modelo de
        Prevención de Delitos Ley 20.393 (MPD), el que tiene como objetivo prevenir y detectar
        situaciones que puedan significar la comisión de los delitos de:
      </p>
      <h4>Lavado de Activos</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que implique ocultar o
        disimular el origen ilícito de determinados bienes, o lucrar con ellos, a sabiendas que
        provienen de la perpetración de delitos relacionados con el tráfico ilícito de drogas,
        terrorismo, tráfico de armas, promoción prostitución infantil, secuestro, cohecho o soborno,
        u otros delitos base de lavado de activos.
      </p>
      <h4>Financiamiento del Terrorismo</h4>
      <p>
        Cualquier acto que implique solicitar, recaudar o proveer fondos con la finalidad de que se
        utilicen en la comisión delitos terroristas señalados en la Ley N°18.314.
      </p>
      <h4>Soborno a funcionarios públicos</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que implique ofrecer, dar o
        prometer, o bien aceptar la entrega a un funcionario público de un beneficio de cualquier
        naturaleza, directa o indirectamente, por el solo hecho de ser funcionario público o para
        que realice u omita realizar los deberes propios de su cargo o lo haga con infracción de
        ellos, para que cometa un delito, o para que mediante cualquier acto beneficie a la
        Empresa.
      </p>
      <h4>Receptación</h4>
      <p>
        Cualquier acto que implique almacenar, transportar, comprar, vender, transformar o
        comercializar, en cualquier forma, especies hurtadas, robadas u objeto de abigeato, de
        receptación o de apropiación indebida.
      </p>
      <h4>Corrupción o soborno entre particulares</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que consista en dar, ofrecer
        o aceptar la entrega a un trabajador o representante de una contraparte comercial de un
        beneficio económico o de otra naturaleza, para sí o un tercero, para que favorezca o por
        haber favorecido la contratación de la Empresa respecto de los bienes o servicios que ésta
        provee.
      </p>
      <h4>Apropiación Indebida</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que implique apropiarse o
        distraer dinero, efectos o cualquiera otra cosa mueble que se hubiere recibido en
        depósito, comisión o administración, o por otro título que produzca obligación de
        entregarla o devolverla.
      </p>
      <h4>Administración Desleal</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que tiene a su cargo el
        cuidado o la gestión del patrimonio de otra persona natural o jurídica, o de alguna parte
        de ese patrimonio, en virtud de la ley, de una orden de la autoridad o de un acto o
        contrato, le cause perjuicios, sea ejerciendo abusivamente facultades para disponer por
        cuenta de ella u obligarla, sea ejecutando u omitiendo cualquier otra acción de modo
        manifiestamente contrario al interés del titular del patrimonio afectado.
      </p>
      <h4>Negociación Incompatible</h4>
      <p>
        Cualquier acto de un director o Gerente de la Empresa de una sociedad anónima que
        implique interesarse directa o indirectamente en cualquier negociación, actuación,
        contrato, operación o gestión que involucre a la sociedad, incumpliendo las condiciones
        establecidas por la ley.
      </p>
      <h4>Contaminación de cuerpos de agua</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa que implique introducir o
        mandar a introducir en el mar, ríos, lagos o cualquier otro cuerpo de agua, agentes
        contaminantes químicos, biológicos o físicos que causen daño a los recursos
        hidrobiológicos.
      </p>
      <h4>Instrucción de asistencia a trabajadores con aislamiento sanitario</h4>
      <p>
        Cualquier acto de un trabajador o representante de la Empresa con capacidad para ordenar
        el trabajo en la Empresa, que implique instruir a un trabajador a que asista a su lugar de
        trabajo cuando este es distinto a su residencia, en circunstancias de encontrarse en
        situación de confinamiento sanitario decretado por la autoridad sanitaria (ya sea porque
        tiene COVID-19 o es contacto estrecho o alguna situación análoga).
      </p>
      <h4>Delitos señalados en el Titulo II de la Ley 17.798 sobre Control de Armas</h4>
      <p>
        Consiste en cometer alguno de los delitos señalados en los artículos 8 y siguientes de la
        Ley 17.798 sobre control de armas, como, por ejemplo: financiar o colaborar con la
        formación de milicias privadas, grupos de combate o partidas militarmente organizadas.
      </p>
      <h4>Tráfico de migrantes</h4>
      <p>
        Consiste en captar, trasladar, acoger o recibir personas para que sean objeto de alguna
        forma de explotación sexual, incluyendo la pornografía, trabajos o servicios forzados,
        servidumbre o esclavitud o prácticas análogas a ésta, o extracción de órganos. Se comete
        el delito cuando las acciones anteriores se han verificado mediante violencia,
        intimidación, coacción, engaño, abuso de poder, aprovechamiento de una situación de
        vulnerabilidad o de dependencia de la víctima, o la concesión o recepción de pagos u otros
        beneficios para obtener el consentimiento de una persona que tenga autoridad sobre otra.
      </p>
      <h4>Delitos Informáticos</h4>
      <p>
        Consiste en cometer alguno de los delitos señalados en dicha norma, como, por ejemplo:
        Ataque a la integridad de un sistema informático (artículo 1°), acceso ilícito (artículo
        2°), interceptación ilícita (artículo 3°), ataque a la integridad de los datos
        informáticos (artículo 4°), falsificación informática (artículo 5°), receptación de datos
        informáticos (artículo 6°), fraude informático (artículo 7°), abuso de los dispositivos
        (artículo 8°)
      </p>
      <p>
        Pincha{' '}
        <a
          target="_blank"
          href="https://www.bcn.cl/leychile/navegar?idNorma=1008668&idVersion=2022-12-21&idParte=8811454"
          rel="noopener noreferrer"
        >
          aquí
        </a>{' '}
        para más información acerca de la Ley 20.393.
      </p>
      <div>
        <h2>LÍNEA DE DENUNCIAS</h2>
        <p>
          En el contexto del Modelo de Prevención de Delitos, se ha habilitado una Línea de
          Denuncias en la que se podrán reportar situaciones que puedan constituir alguno de
          los delitos señalados anteriormente.
        </p>
        <p>
          La Línea de Denuncias es un canal de comunicación de carácter confidencial
          destinado a tomar conocimiento y gestionar adecuadamente las denuncias respecto de
          la comisión de los delitos abordados por el Modelo de Prevención de Delitos.
        </p>
        <h3>IMPORTANTE:</h3>
        <ul>
          <li>
            Esta línea de denuncias no es un servicio de emergencia. Si estás en una
            situación urgente y requieres ayuda, contacta a los servicios de urgencia o
            seguridad correspondientes.
          </li>
          <li>
            Esta línea de denuncias no es una línea de reclamos, por lo que
            agradeceremos utilizar para ese efecto nuestro Fono Clientes:
            +56 672 215 555.
          </li>
          <li>
            Esta línea de denuncias debe ser utilizada con responsabilidad y de
            buena fe, teniendo clara conciencia que la entrega de información
            falsa o realizar denuncias maliciosas con el fin de generar algún
            perjuicio a otra persona, puede implicar sanciones administrativas y
            dar lugar a un proceso civil o criminal.
          </li>
        </ul>
        <p>Puede realizar denuncias a través de los siguientes medios:</p>
        <ul>
          <li>Escribiéndonos al correo: <a href="mailto:denuncias@patagoniasostenible.cl">denuncias@patagoniasostenible.cl</a></li>
          <li>
            Para el ingreso de una denuncia es posible realizarlo
            siguiendo el proceso dentro del siguiente enlace:
          </li>
          <li>
            <a href="/denuncias">INGRESAR DENUNCIA</a>
          </li>
        </ul>
        {/* <div className={styles.denunciaFiles}>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-PRO-003-Codigo-de-Etica.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR EL CODIGO ÉTICA</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-006-Politica-Prevencion-de-Delitos-Ley-20393.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLITICA DE PREVENCION DE DELITO</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-007-Politica-Donaciones-Auspicios-y-Patrocinios.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLITICA DE DONACIONES AUSPICIO Y PATROCINIOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-008-Politica-Relacionamiento-con-Funcionarios-Publicos.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA RELACIONAMIENTO CON FUNCIONARIOS PÚBLICOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-009-Politica-de-Regalos.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA DE REGALOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-11-Politica-de-manejo-de-conflicto-de-interes.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA DE MANEJO DE CONFLICTO DE INTERES</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-PRO-007-Prevencion-y-Procedimiento-de-Investigacion-de-acoso-sexual-laboral-y-violencia-en-el-trabajo.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR PREVENCIÓN Y PROCEDIMIENTO DE INVESTIGACIÓN DE ACOSO SEXUAL, LABORAL Y VIOLENCIA EN EL TRABAJO</a>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Compliance;
